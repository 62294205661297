/**
 * Created by andyosullivan on 04/04/2021.
 */
import React, { Component } from "react";
import "./Home.css";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import rightArrowBlack from '../../assets/images/rightArrowBlack.png';
import Card from "react-bootstrap/Card";
import { tv_details } from '../../assets/tv/tv_details';
import { movie_details } from '../../assets/movies/movie_details';
import { recipe_details } from '../../assets/recipes/recipe_details';

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tvEpisodes: tv_details.slice(1, 9).map(episode => ({ ...episode, loadedImage: null })),
            movies: movie_details.slice(0, 8).map(movie => ({ ...movie, loadedImage: null })),
            recipes: recipe_details.slice(0, 5).map(recipe => ({ ...recipe, loadedImage: null })),
            loadedImages: {
                heroImage: null,
                tvEpisodes: {},
                movies: {},
                recipes: {}
            },
            tv: {
                heroText: "Starting off slow, series 2 of The Rings of Power builds into epic" +
                    " movie quality entertainment.",
                title: "Rings of Power Series 2 Review - Slow Start, Epic End",
                 name: "Rings of Power Series 2 Review - Slow Start, Epic End",
                tv: "rings-of-power-series-2-review-slow-start-epic-end",
                subtitle: "Epic movie quality entertainment",
                reviewer: {name:"Andy O'Sullivan",twitter:"https://twitter.com/noadshere"},
                rating: 5,
                wifereview: "",
                kidsreview: "",
                date: "3/Oct/24",
               image_1: {image: "ringsofpowers02_001.jpg", text: "Honestly, we still have no idea how the rings work", alt: ""},
                spoliers: true,
                darkTitle: false,
                summary: "Epic movie quality entertainment"
            },
            movie: {
                tv: "family-movie-night-signs",
                spoliers: true,
                heroText: "A classic, scary family movie from 2002, re-reviewed for our Family Movie Night series.",
                allMovies: true,
                title: "Family Movie Review - Signs",
                summary: "A classic, scary family movie",
                date: "23/Aug/24",
                reviewer: { name: "Andy O'Sullivan", twitter: "https://twitter.com/LeMarquisOfAndy" },
                name: "Family Movie Night - Signs",
                movie: "family-movie-night-signs",
                subtitle: "In our top ten movies of all time",
                image_1: { image: "signs_003.jpg", text: "", alt: "" }
            },
            recipe: {
                name: "test",
                recipe: "test-recipe",
                date: "11/Jan/22",
                reviewer: "Andy O'Sullivan",
                wifereview: "test",
                kidsreview: "test",
                image_1: "dune001.jpg",
                image_2: "dune001.jpg",
                image_3: "dune001.jpg",
                image_4: "dune001.jpg",
                image_5: "dune001.jpg",
                image_6: "dune001.jpg",
                paragraph_1: "test",
                paragraph_2: "test",
                paragraph_3: "test",
                paragraph_5: "test ",
                paragraph_6: "test",
                paragraph_7: "",
                paragraph_8: "",
                paragraph_9: "",
                paragraph_10: "",
                ingredients: ["test", "test"]
            }
        };
    }

    componentDidMount() {
        this.deleteAllCookies();

        // Load movie image
        import("../../assets/images/tv/" + this.state.tv.image_1.image)
            .then(image => {
                this.setState((prevState) => ({
                    loadedImages: { ...prevState.loadedImages, heroImage: image.default }
                }));
            })
            .catch(err => console.error("Error loading image:", err));

        // Load TV episode images
        this.state.tvEpisodes.forEach((item) => {
            import("../../assets/images/tv/" + item.image_1.image)
                .then((image) => {
                    this.setState((prevState) => ({
                        loadedImages: {
                            ...prevState.loadedImages,
                            tvEpisodes: { ...prevState.loadedImages.tvEpisodes, [item.name]: image.default }
                        }
                    }));
                })
                .catch(err => console.error("Error loading image:", err));
        });

        // Load movie images
        this.state.movies.forEach((item) => {
            import("../../assets/images/" + item.image_1.image)
                .then((image) => {
                    this.setState((prevState) => ({
                        loadedImages: {
                            ...prevState.loadedImages,
                            movies: { ...prevState.loadedImages.movies, [item.name]: image.default }
                        }
                    }));
                })
                .catch(err => console.error("Error loading image:", err));
        });

        // Load recipe images
        this.state.recipes.forEach((item) => {
            import("../../assets/images/" + item.image_1.image)
                .then((image) => {
                    this.setState((prevState) => ({
                        loadedImages: {
                            ...prevState.loadedImages,
                            recipes: { ...prevState.loadedImages.recipes, [item.name]: image.default }
                        }
                    }));
                })
                .catch(err => console.error("Error loading image:", err));
        });
    }

    deleteAllCookies() {
        var cookies = document.cookie.split(";");

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }

        localStorage.clear();
        sessionStorage.clear();
    }

    render() {
        return (
            <div className="divmor">
                <Container>
                    <Row className="blurbRow">
                        <br />
                        <Row className="cardRow">
                            <Col sm={8}>
                                <div className="heroImageDiv"
                                     style={{
                                         backgroundImage: this.state.loadedImages.heroImage ? `url(${this.state.loadedImages.heroImage})` : 'none',
                                         backgroundSize: "cover",
                                     }}
                                >
                                </div>
                            </Col>
                            <Col sm={4}>
                                <p className="blurbTitle"><span className="titleSpan">{this.state.tv.title} </span><br />
                                    {this.state.tv.heroText} <a className="aboutLink" href={"/tv/" + this.state.tv.tv}><strong>Read the review</strong></a>.</p>
                            </Col>

                            <Col className="latestTV" sm={12}><h1 className="sectionTitle">Latest TV <a className="aboutLink" href="/tv/all">(or see all) </a></h1></Col>
                            {this.state.tvEpisodes.map((item) => (
                                <Col className="tvCol" sm={4} lg={4} key={item.name}>
                                    <div className="bgImageDiv" style={{
                                        backgroundImage: this.state.loadedImages.tvEpisodes[item.name] ? `url(${this.state.loadedImages.tvEpisodes[item.name]})` : 'none',
                                        backgroundSize: "cover"
                                    }}>
                                        <Card className="contentCard tv">
                                            <a className="cardLink" href={"/tv/" + item.tv}>
                                                <p className={!item.darkTitle ? "cardLinkText" : "cardLinkTextDark"}>{item.name}</p>
                                            </a>
                                        </Card>
                                    </div>
                                    <p className="tileText">{item.summary}</p>
                                </Col>
                            ))}
                            {/* ... */}
                            {/* Render Movies Section */}
                            <Col className="movieSection" sm={12}>
                                <h1 className="sectionTitle">Latest Movies <a className="aboutLink" href="/movies/all">(or see all)</a></h1>
                            </Col>
                            {this.state.movies.map((item) => (
                                <Col className="tvCol" sm={4} lg={4} key={item.name}>
                                    <div className="bgImageDiv" style={{
                                        backgroundImage: this.state.loadedImages.movies[item.name] ? `url(${this.state.loadedImages.movies[item.name]})` : 'none',
                                        backgroundSize: "cover"
                                    }}>
                                        <Card className="contentCard tv">
                                            <a className="cardLink" href={"/movies/" + item.movie}>
                                                <p className="cardLinkText">{item.name}</p>
                                            </a>
                                        </Card>
                                    </div>
                                    <p className="tileText">{item.summary}</p>
                                </Col>
                            ))}

                            {/* Render Recipes Section */}
                            <Col sm={12}>
                                <h1 className="sectionTitle">Latest Recipes <a className="aboutLink" href="/recipes/all">(or see all)</a></h1>
                            </Col>
                            {this.state.recipes.map((item) => (
                                <Col className="tvCol" sm={4} lg={4} key={item.name}>
                                    <div className="bgImageDiv" style={{
                                        backgroundImage: this.state.loadedImages.recipes[item.name] ? `url(${this.state.loadedImages.recipes[item.name]})` : 'none',
                                        backgroundSize: "cover"
                                    }}>
                                        <Card className="contentCard tv">
                                            <a className="cardLink" href={"/recipes/" + item.recipe}>
                                                <p className="cardLinkText">{item.name}</p>
                                            </a>
                                        </Card>
                                    </div>
                                </Col>
                            ))}

                        </Row>
                    </Row>
                </Container>
            </div>
        );
    }
}
